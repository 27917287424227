// src/pages/DashboardPage.js
import React, { useEffect, useState } from 'react';
import { Container, Card, Button, Table, Row, Col, Modal, Form, InputGroup, Tabs, Tab } from 'react-bootstrap';
import { FaTrashAlt, FaEdit, FaSave } from 'react-icons/fa'; // Import the trash icon
import {
  fetchUsersByRole,
  fetchAllUsers,
  fetchUserDetails,
  updateUserDetails,
  fetchMatches,
  fetchApplicationsForMatch,
  fetchApplicationsByUser,
  updateMatchDetails,
  fetchMatchDetails,
  updateApplicationDetails,
  deleteUser,
  fetchLinkedUsers,
} from '../services/firebaseServices';
import '../styles/DashboardPage.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import TicketEmailModal from '../components/TicketEmailModal';
import axios from 'axios';

function DashboardPage() {
  const [tempUsers, setTempUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [matches, setMatches] = useState([]);
  const [matchApplications, setMatchApplications] = useState({});
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [showMatchModal, setShowMatchModal] = useState(false);
  const [showAllUsers, setShowAllUsers] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [filterRole, setFilterRole] = useState('');
  const [filterMuscPaid, setFilterMuscPaid] = useState('');
  const [filterRegistered, setFilterRegistered] = useState('');
  const USERS_PER_PAGE = 10;
  const [showApplicationsModal, setShowApplicationsModal] = useState(false);
  const [applicationsList, setApplicationsList] = useState([]);
  const [showUserApplicationsModal, setShowUserApplicationsModal] = useState(false);
  const [userApplications, setUserApplications] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showTicketEmailModal, setShowTicketEmailModal] = useState(false);
  const [selectedMatchForEmail, setSelectedMatchForEmail] = useState(null);
  const [editUserId, setEditUserId] = useState(null); // To track which user is being edited
  const [editUserDetails, setEditUserDetails] = useState({}); // To store editable user details
  const [recipientEmails, setRecipientEmails] = useState([]);
  const [linkedUsers, setLinkedUsers] = useState([]);
  const [selectedUserForLinks, setSelectedUserForLinks] = useState(null);



  useEffect(() => {
    const loadTempUsers = async () => {
      try {
        const users = await fetchUsersByRole('temp');
        setTempUsers(users);
      } catch (error) {
        console.error('Error fetching temp users:', error);
      }
    };

    const loadAllUsers = async () => {
      try {
        const users = await fetchAllUsers();
        setAllUsers(users);
      } catch (error) {
        console.error('Error fetching all users:', error);
      }
    };

    loadTempUsers();
    loadAllUsers();
    loadMatches(); // Load matches on initial load
  }, []);

  const calculateTicketType = (dob, memberName) => {

    console.log("DOB:",dob);
    if (!dob) return 'Unknown';
  
    // Convert Firestore Timestamp to a JavaScript Date object
    const birthDate = dob.toDate ? dob.toDate() : new Date(dob.seconds * 1000);
    const today = new Date();
  
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
  
    // Adjust age if the current month and day haven't reached the birth month and day yet
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    // Return the correct age group
    if (age <= 16) {
      return 'U16';
    } else if (age >= 17 && age <= 18) {
      return '17-18';
    } else if (age >= 18 && age <= 20) {
      return '18-20';
    } else if (age >= 65) {
      return 'O65';
    } else {
      return 'Adult';
    }
  };

  const handleViewLinkedUsers = async (userId) => {
    try {
      // Fetch user details to get the user's name
      const userDetails = await fetchUserDetails(userId);
  
      if (userDetails) {
        setSelectedUserForLinks(`${userDetails.FName} ${userDetails.LName}`); // Set user's full name
      } else {
        setSelectedUserForLinks('Unknown User');
      }
  
      // Fetch linked users
      const linkedUsers = await fetchLinkedUsers(userId);
      setLinkedUsers(linkedUsers);
    } catch (error) {
      console.error('Error fetching linked users:', error);
    }
  };
  
  const handleToggleField = async (userId, field, newValue) => {
    try {
      await updateUserDetails(userId, { [field]: newValue });
  
      // Update the state to reflect changes
      setAllUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId ? { ...user, [field]: newValue } : user
        )
      );
      setTempUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId ? { ...user, [field]: newValue } : user
        )
      );
    } catch (error) {
      console.error(`Error toggling ${field}:`, error);
    }
  };
  
  
  const handleSendInformationEmail = async (user) => {
    try {
      // Ensure the user has a name and email
      if (!user.FName || !user.email) {
        alert('User must have a name and email to send information.');
        return;
      }
  
      // Prepare the data
      const name = `${user.FName} ${user.LName}`.trim();
      const email = user.email;
  
      // Make the request to the cloud function
      const response = await axios.post(
        'https://us-central1-corkmusc-f2191.cloudfunctions.net/sendInterestEmail', {
        name,
        email,
      });
  
      if (response.status === 200) {
        alert('Information email sent successfully.');
      } else {
        console.error('Error sending information email:', response.data);
        alert('Failed to send information email. Please try again.');
      }
    } catch (error) {
      console.error('Error sending information email:', error);
      alert('Failed to send information email. Please try again.');
    }
  };

  const handleDeleteUser = async (userId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this user? This action cannot be undone.');
  
    if (confirmDelete) {
      try {
        await deleteUser(userId);
        
        // Update local state to reflect changes
        setTempUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
        setAllUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
  
        alert('User successfully deleted.');
      } catch (error) {
        console.error('Error deleting user:', error);
        alert('Failed to delete user. Please try again.');
      }
    }
  };

  const loadMatches = async () => {
    try {
      const matchesData = await fetchMatches();
      // Sort matches by applicationOpenDate in ascending order
      matchesData.sort((a, b) => {
        return a.applicationOpenDate?.seconds - b.applicationOpenDate?.seconds;
      });
      setMatches(matchesData);

      // Load applications for each match
      const applicationsData = {};
      for (let match of matchesData) {
        const applications = await fetchApplicationsForMatch(match.id);
        applicationsData[match.id] = applications.length;
      }
      setMatchApplications(applicationsData);
    } catch (error) {
      console.error('Error fetching matches:', error);
    }
  };

  const handleUpdateUser = async (userId, key, value) => {
    try {
      await updateUserDetails(userId, { [key]: value });
      // Update local state to reflect changes
      setTempUsers((prevUsers) =>
        prevUsers.map((user) => (user.id === userId ? { ...user, [key]: value } : user))
      );
      setAllUsers((prevUsers) =>
        prevUsers.map((user) => (user.id === userId ? { ...user, [key]: value } : user))
      );
    } catch (error) {
      console.error('Error updating user details:', error);
    }
  };

  const handleResetMUSCPaid = async (status = 'no') => {
    try {
      const updates = allUsers.map(async (user) => {
        if (user.muscPaid !== status) {
          await updateUserDetails(user.id, { muscPaid: status });
        }
      });
      await Promise.all(updates);
  
      // Update local state
      setAllUsers((prevUsers) =>
        prevUsers.map((user) => ({ ...user, muscPaid: status }))
      );
      setTempUsers((prevUsers) =>
        prevUsers.map((user) => ({ ...user, muscPaid: status }))
      );
  
      alert(`All users' MUSC Paid status has been reset to '${status}'.`);
    } catch (error) {
      console.error('Error resetting MUSC Paid:', error);
    }
  };

  const handleResetRegistered = async (status = 'no') => {
    try {
      const updates = allUsers.map(async (user) => {
        if (user.registered !== status) {
          await updateUserDetails(user.id, { registered: status });
        }
      });
      await Promise.all(updates);
  
      // Update local state
      setAllUsers((prevUsers) =>
        prevUsers.map((user) => ({ ...user, registered: status }))
      );
      setTempUsers((prevUsers) =>
        prevUsers.map((user) => ({ ...user, registered: status }))
      );
  
      alert(`All users' Registered status has been reset to '${status}'.`);
    } catch (error) {
      console.error('Error resetting Registered:', error);
    }
  };

  const handleSendTicketEmail = async (match) => {
    try {
      // Fetch the full details of the selected match
      const matchDetails = await fetchMatchDetails(match.id);
  
      // Fetch all applications for the provided matchId
      const applicationsSnapshot = await fetchApplicationsForMatch(match.id);
  
      if (applicationsSnapshot.length === 0) {
        alert("No applications found for this match.");
        return;
      }
  
      // Filter applications to only get the successful ones
      const successfulApplications = applicationsSnapshot.filter(application => application.successful);
  
      if (successfulApplications.length === 0) {
        alert("No successful applications for this match.");
        return;
      }
  
      // Gather all the userIDs from the successful applications
      const userIds = successfulApplications.map(app => app.userID);
  
      // Fetch email addresses for all relevant userIDs from the members collection
      const emails = [];
      for (const userId of userIds) {
        const user = await fetchUserDetails(userId);
        if (user && user.email) {
          emails.push(user.email);
        }
      }
  
      if (emails.length === 0) {
        alert("No valid email addresses found for successful applicants.");
        return;
      }
  
      setRecipientEmails(emails); // Update state with the filtered recipient emails
      setSelectedMatchForEmail(matchDetails); // Update state with full details of the selected match
      setShowTicketEmailModal(true); // Show the modal
  
    } catch (error) {
      console.error('Error fetching match details or successful applications:', error);
      alert('Error fetching match details or successful applications. Please try again.');
    }
  };
  
  
  

      const handleTicketEmailSubmit = async (data, recipientEmails) => {
        try {
          // Update the match document with ticket prices, stand, rows, etc.
          await updateMatchDetails(data.matchId, {
            ticketPrices: data.ticketPrices,
            stand: data.stand,
            rows: data.rows,
            paymentDueDate: data.paymentDueDate,
          });
      
          // Call the cloud function using an HTTP request to send the email
          const response = await axios.post('https://us-central1-corkmusc-f2191.cloudfunctions.net/sendTicketEmail', {
            matchId: data.matchId,
            matchName: data.matchName,
            paymentDueDate: data.paymentDueDate,
            ticketPrices: data.ticketPrices,
            stand: data.stand,
            rows: data.rows,
            recipientEmails: recipientEmails, // Pass the list of recipients to the cloud function
          });
      
          if (response.status === 200) {
            alert("Ticket reservation emails have been sent to successful applicants.");
          } else {
            console.error("Error sending emails:", response.data);
            alert("Failed to send emails. Please try again.");
          }
        } catch (error) {
          console.error("Error updating match or sending emails:", error);
          alert("Failed to send emails. Please try again.");
        }
      };
  

      const getMatchStatus = (match) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set today's time to midnight (start of the day)
      
        const openDate = match.applicationOpenDate ? match.applicationOpenDate.toDate() : null;
        const closeDate = match.applicationCloseDate ? match.applicationCloseDate.toDate() : null;
      
        if (openDate && closeDate) {
          // Normalize openDate to remove time and match just the date
          const normalizedOpenDate = new Date(openDate);
          normalizedOpenDate.setHours(0, 0, 0, 0); // Set openDate time to midnight
      
          const normalizedCloseDate = new Date(closeDate);
          normalizedCloseDate.setHours(0, 0, 0, 0); // Set closeDate time to midnight
      
          if (today < normalizedOpenDate) {
            return 'Upcoming';
          } else if (today >= normalizedOpenDate && today <= normalizedCloseDate) {
            return 'Open';
          } else if (today > normalizedCloseDate) {
            return 'Closed';
          }
        }
        
        return 'Unknown';
      };

  const handleManageMatch = (match) => {
    setSelectedMatch({
      ...match,
      matchDate: match.matchDate ? match.matchDate.toDate() : null,
      applicationOpenDate: match.applicationOpenDate ? match.applicationOpenDate.toDate() : null,
      applicationCloseDate: match.applicationCloseDate ? match.applicationCloseDate.toDate() : null,
    });
    setShowMatchModal(true);
  };

  const handleViewApplications = async (matchId) => {
    try {
      const applications = await fetchApplicationsForMatch(matchId);

      // Fetch user details for each application using member ID
      const applicationsWithUserDetails = await Promise.all(
        applications.map(async (application) => {
          const user = await fetchUserDetails(application.userID);
          return {
            ...application,
            FName: user?.FName || 'Unknown',
            LName: user?.LName || 'Unknown',
            dob: user?.dob || null,
          };
        })
      );

      // Sort applications by user's last name (LName), handling possible undefined values
      applicationsWithUserDetails.sort((a, b) => {
        if (a.LName && b.LName) {
          return a.LName.localeCompare(b.LName);
        } else if (!a.LName) {
          return 1; // Sort undefined LName to the end
        } else if (!b.LName) {
          return -1; // Sort undefined LName to the end
        } else {
          return 0;
        }
      });

      setApplicationsList(applicationsWithUserDetails);
      setShowApplicationsModal(true);
    } catch (error) {
      console.error('Error fetching applications for match:', error);
    }
  };

  const handleViewUserApplications = async (userId) => {
    try {
      setSelectedUser(userId);
      const applications = await fetchApplicationsByUser(userId);
      setUserApplications(applications);
      setShowUserApplicationsModal(true);
    } catch (error) {
      console.error('Error fetching user applications:', error);
    }
  };

  const handleCloseApplicationsModal = () => {
    setShowApplicationsModal(false);
    setApplicationsList([]);
  };

  const handleCloseUserApplicationsModal = () => {
    setShowUserApplicationsModal(false);
    setUserApplications([]);
  };

  const handleCloseMatchModal = () => {
    setShowMatchModal(false);
    setSelectedMatch(null);
  };

  const handleMatchInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedMatch((prev) => ({
      ...prev,
      [name]: value ? new Date(value) : null,
    }));
  };

  const handleSaveMatchDetails = async () => {
    try {
      await updateMatchDetails(selectedMatch.id, {
        ...selectedMatch,
        matchDate: selectedMatch.matchDate,
        applicationOpenDate: selectedMatch.applicationOpenDate,
        applicationCloseDate: selectedMatch.applicationCloseDate,
      });
      setShowMatchModal(false);
      setSelectedMatch(null);
      loadMatches(); // Refresh the match list after saving
    } catch (error) {
      console.error('Error updating match details:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page after a search
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedUsers = React.useMemo(() => {
    let sortableUsers = showAllUsers ? allUsers : tempUsers;

    if (sortConfig.key) {
      sortableUsers = [...sortableUsers].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    return sortableUsers;
  }, [showAllUsers, allUsers, tempUsers, sortConfig]);

  const filteredUsers = sortedUsers.filter(
    (user) =>
      (!filterRole || user.role === filterRole) &&
      (!filterMuscPaid || user.muscPaid === filterMuscPaid) &&
      (!filterRegistered || user.registered === filterRegistered) &&
      (user.FName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.LName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.membershipNumber.toString().includes(searchQuery))
  );  

  const paginatedUsers = filteredUsers.slice(
    (currentPage - 1) * USERS_PER_PAGE,
    currentPage * USERS_PER_PAGE
  );

  const handleNextPage = () => {
    if (currentPage * USERS_PER_PAGE < filteredUsers.length) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleEditUser = (user) => {
    setEditUserId(user.id);
    setEditUserDetails({ ...user });
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSaveUserDetails = async () => {
    try {
      await updateUserDetails(editUserId, {
        FName: editUserDetails.FName,
        LName: editUserDetails.LName,
        membershipNumber: editUserDetails.membershipNumber,
      });
      setTempUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === editUserId ? { ...user, ...editUserDetails } : user
        )
      );
      setAllUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === editUserId ? { ...user, ...editUserDetails } : user
        )
      );
      setEditUserId(null);
      setEditUserDetails({});
    } catch (error) {
      console.error('Error saving user details:', error);
    }
  };

  return (
    <Container className="mt-4">
        {/* User Section */}
      <Row className="mb-3">
      <Col xs={12}>
        <Button
        variant="primary"
        className="mb-3"
        onClick={() => {
          setShowAllUsers(!showAllUsers);
          setCurrentPage(1); // Reset to the first page
        }}
      >
        {showAllUsers ? 'Show New Users' : 'Show All Users'}
      </Button>
        <Button
          variant="danger"
          className="mb-3 ms-2"
          onClick={() => handleResetMUSCPaid('no')}
        >
          Reset MUSC Paid to No
        </Button>
        <Button
          variant="warning"
          className="mb-3 ms-2"
          onClick={() => handleResetRegistered('no')}
        >
          Reset Registered to No
        </Button>

          {showAllUsers && (
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder="Search by name or membership number"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </InputGroup>
          )}
          </Col>
          </Row>

          <Row>
          {/* Users Table */}
            <Col xs={12} lg={6}>
              <Card className="shadow-sm">
                <Card.Header className="text-center">
                  <h4>Users</h4>
                </Card.Header>
                <Card.Body className="table-responsive">
                  <div className="mb-3 d-flex justify-content-start align-items-center flex-wrap">
                    <Form.Select
                      size="sm"
                      value={filterRole}
                      onChange={(e) => setFilterRole(e.target.value)}
                      className="me-2 mb-2"
                      style={{ width: '150px' }}
                    >
                      <option value="">Filter by Role</option>
                      <option value="temp">Temp</option>
                      <option value="user">User</option>
                      <option value="admin">Admin</option>
                      <option value="season ticket holder">Season Ticket Holder</option>
                    </Form.Select>
                    <Form.Select
                      size="sm"
                      value={filterMuscPaid}
                      onChange={(e) => setFilterMuscPaid(e.target.value)}
                      className="me-2 mb-2"
                      style={{ width: '150px' }}
                    >
                      <option value="">Filter by MUSC Paid</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Select>
                    <Form.Select
                      size="sm"
                      value={filterRegistered}
                      onChange={(e) => setFilterRegistered(e.target.value)}
                      className="me-2 mb-2"
                      style={{ width: '150px' }}
                    >
                      <option value="">Filter by CorkMUSC Registered</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Select>
                  </div>
                  {paginatedUsers && paginatedUsers.length > 0 ? (
                    <Table striped bordered hover size="sm" responsive>
                      <thead>
                        <tr>
                          <th onClick={() => handleSort('FName')}>Name</th>
                          <th onClick={() => handleSort('membershipNumber')}>Membership</th>
                          <th>Role</th>
                          <th>MUSC Paid</th>
                          <th>Registered</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedUsers.map((user) => (
                          <tr
                            key={user.id}
                            className={selectedUserForLinks === user.id ? 'table-active' : ''}
                            onClick={() => handleViewLinkedUsers(user.id)}
                            style={{ cursor: 'pointer' }} // Change cursor to pointer for better UX
                          >
                            <td>{`${user.FName} ${user.LName}`}</td>
                            <td>{user.membershipNumber}</td>
                            <td>
                              <div className="d-flex align-items-center">
                              <Form.Select
                                value={user.role || 'temp'}
                                onChange={(e) =>
                                  handleUpdateUser(user.id, 'role', e.target.value.toLowerCase())
                                }
                                size="sm"
                                className="me-2"
                              >
                                <option value="temp">Temp</option>
                                <option value="user">User</option>
                                <option value="admin">Admin</option>
                                <option value="season ticket holder">Season Ticket Holder</option>
                              </Form.Select>
                              </div>
                              </td>
                              <td>
                              <div
                              className="d-flex align-items-center justify-content-center"
                                style={{
                                  display: 'inline-block',
                                  padding: '5px 10px',
                                  borderRadius: '15px',
                                  backgroundColor: user.muscPaid === 'yes' ? 'green' : 'rgb(139,0,0)',
                                  color: 'white',
                                  textAlign: 'center',
                                  cursor: 'pointer',
                                  minWidth: '35px',
                                  fontSize: '10px',
                                }}
                                onClick={() =>
                                  handleToggleField(user.id, 'muscPaid', user.muscPaid === 'yes' ? 'no' : 'yes')
                                }
                              >
                                {user.muscPaid === 'yes' ? 'Yes' : 'No'}
                              </div>
                              </td>
                              <td>
                              <div
                                className="d-flex align-items-center justify-content-center"
                                style={{
                                  display: 'inline-block',
                                  padding: '5px 10px',
                                  borderRadius: '15px',
                                  backgroundColor: user.registered === 'yes' ? 'green' : 'rgb(139,0,0)',
                                  color: 'white',
                                  textAlign: 'center',
                                  cursor: 'pointer',
                                  minWidth: '35px',
                                  fontSize: '10px',
                                  marginLeft: '10px',
                                  marginRight: '10px',
                                }}
                                onClick={() =>
                                  handleToggleField(user.id, 'registered', user.registered === 'yes' ? 'no' : 'yes')
                                }
                              >
                                {user.registered === 'yes' ? 'Yes' : 'No'}
                              </div>
                              </td>
                              <td>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => handleDeleteUser(user.id)}
                                >
                                  <FaTrashAlt />
                                </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p>No users available.</p>
                  )}
                  <div className="d-flex justify-content-between">
                    <Button
                      variant="outline-primary"
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="outline-primary"
                      onClick={handleNextPage}
                      disabled={currentPage * USERS_PER_PAGE >= filteredUsers.length}
                    >
                      Next
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>


          {/* Linked Users Table */}
          <Col xs={12} lg={6}>
            <Card className="shadow-sm">
              <Card.Header className="text-center">
                <h4>Linked Users</h4>
              </Card.Header>
              <Card.Body className="table-responsive">
                {selectedUserForLinks ? (
                  <>
                    <p>
                      Showing linked users for: <strong>{selectedUserForLinks}</strong>
                    </p>
                    {linkedUsers.length > 0 ? (
                      <Table striped bordered hover size="sm" responsive>
                        <thead>
                          <tr>
                            <th>Number</th>
                            <th>Name</th>
                            <th>Account Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {linkedUsers.map((linkedUser) => (
                            <tr key={linkedUser.id}>
                              <td>{`${linkedUser.membershipNumber}`}</td>
                              <td>{`${linkedUser.FName} ${linkedUser.LName}`}</td>
                              <td>{calculateTicketType(linkedUser.dob, `${linkedUser.FName} ${linkedUser.LName}`)}</td>
                              <td>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => handleDeleteUser(linkedUser.id)}
                                >
                                  Remove Link
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <p>No linked users found.</p>
                    )}
                  </>
                ) : (
                  <p>Select a user to view linked users.</p>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      {/* Match Overview Section */}
      <Row className="mb-4">
        <Col xs={12} lg={6}>
          <Card className="shadow-sm mb-4 mb-lg-0">
            <Card.Header className="text-center">
              <h4>Match Overview</h4>
            </Card.Header>
            <Card.Body>
              <Tabs defaultActiveKey="open" id="match-overview-tabs" className="mb-3">
                <Tab eventKey="open" title="Open">
                  {matches.filter(match => getMatchStatus(match) === 'Open').length > 0 ? (
                    <Table striped bordered hover size="sm" responsive>
                      <thead>
                        <tr>
                          <th>Match Name</th>
                          <th>Applications</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {matches
                          .filter(match => getMatchStatus(match) === 'Open')
                          .map((match) => (
                            <tr key={match.id}>
                              <td>{match.name}</td>
                              <td>
                                <Button
                                  variant="info"
                                  size="sm"
                                  onClick={() => handleViewApplications(match.id)}
                                >
                                  {matchApplications[match.id] || 0} Applications
                                </Button>
                              </td>
                              <td>
                                <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={() => handleManageMatch(match)}
                                >
                                <i className="bi bi-pencil-square"></i> {/* Bootstrap icon */}
                                </Button>

                                <Button variant="outline-success" size="sm" className="ms-2" onClick={() => handleSendTicketEmail(match)}>
                                <i className="bi bi-envelope"></i> {/* Bootstrap icon for email */}
                              </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p>No open matches available.</p>
                  )}
                </Tab>
                <Tab eventKey="upcoming" title="Upcoming">
                  {matches.filter(match => getMatchStatus(match) === 'Upcoming').length > 0 ? (
                    <Table striped bordered hover size="sm" responsive>
                      <thead>
                        <tr>
                          <th>Match Name</th>
                          <th>Applications</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {matches
                          .filter(match => getMatchStatus(match) === 'Upcoming')
                          .map((match) => (
                            <tr key={match.id}>
                              <td>{match.name}</td>
                              <td>
                                <Button
                                  variant="info"
                                  size="sm"
                                  onClick={() => handleViewApplications(match.id)}
                                >
                                  {matchApplications[match.id] || 0} Applications
                                </Button>
                              </td>
                              <td>
                                <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={() => handleManageMatch(match)}
                                >
                                <i className="bi bi-pencil-square"></i> {/* Bootstrap icon */}
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p>No upcoming matches available.</p>
                  )}
                </Tab>
                <Tab eventKey="closed" title="Closed">
                  {matches.filter(match => getMatchStatus(match) === 'Closed').length > 0 ? (
                    <Table striped bordered hover size="sm" responsive>
                      <thead>
                        <tr>
                          <th>Match Name</th>
                          <th>Applications</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {matches
                          .filter(match => getMatchStatus(match) === 'Closed')
                          .map((match) => (
                            <tr key={match.id}>
                              <td>{match.name}</td>
                              <td>
                                <Button
                                  variant="info"
                                  size="sm"
                                  onClick={() => handleViewApplications(match.id)}
                                >
                                  {matchApplications[match.id] || 0} Applications
                                </Button>
                              </td>
                              <td>
                                <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={() => handleManageMatch(match)}
                                >
                                <i className="bi bi-pencil-square"></i> {/* Bootstrap icon */}
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p>No closed matches available.</p>
                  )}
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg={6}>
          <Card className="shadow-sm">
            <Card.Header className="text-center">
              <h4>Statistics Overview</h4>
            </Card.Header>
            <Card.Body>
              <p><strong>Total Registered Users:</strong> {allUsers.length}</p>
              <p><strong>Total Registered Users:</strong> {allUsers.length}</p>
              <p><strong>Total MUSC Paid (Yes):</strong> {allUsers.filter(user => user.muscPaid === 'yes').length}</p>
              <p><strong>Total Registered (Yes):</strong> {allUsers.filter(user => user.registered === 'yes').length}</p>
              <p><strong>Total Matches:</strong> {matches.length}</p>
              <p><strong>Total Pending Applications:</strong> {Object.values(matchApplications).reduce((a, b) => a + b, 0)}</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Ticket Email Modal */}
      {selectedMatchForEmail && (
      <TicketEmailModal
        show={showTicketEmailModal}
        onClose={() => setShowTicketEmailModal(false)}
        onSubmit={(data) => handleTicketEmailSubmit(data, recipientEmails)} // Pass recipientEmails to handleTicketEmailSubmit
        match={selectedMatchForEmail}
        defaultData={selectedMatchForEmail.ticketDetails || {
          paymentDueDate: selectedMatchForEmail.paymentDueDate,
          ticketPrices: selectedMatchForEmail.ticketPrices || {},
          stand: selectedMatchForEmail.stand,
          rows: selectedMatchForEmail.rows
        }}
      />
    )}

      {/* Match Details Modal */}
      <Modal show={showMatchModal} onHide={handleCloseMatchModal}>
        <Modal.Header closeButton>
          <Modal.Title>Match Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedMatch ? (
            <Form>
              <Form.Group controlId="matchName">
                <Form.Label>Match Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={selectedMatch.name || ''}
                  onChange={handleMatchInputChange}
                />
              </Form.Group>
              <Form.Group controlId="competitionType" className="mt-3">
                <Form.Label>Competition Type</Form.Label>
                <Form.Select
                  name="competitionType"
                  value={selectedMatch.competitionType || ''}
                  onChange={handleMatchInputChange}
                >
                  <option value="">Select Competition Type</option>
                  <option value="Premier League">Premier League</option>
                  <option value="European Cup">European Cup</option>
                  <option value="Domestic Cup">Domestic Cup</option>
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="matchDate" className="mt-3">
                <Form.Label>Match Date</Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="matchDate"
                  value={
                    selectedMatch.matchDate
                      ? new Date(selectedMatch.matchDate).toISOString().slice(0, 16)
                      : ''
                  }
                  onChange={handleMatchInputChange}
                />
              </Form.Group>
              <Form.Group controlId="applicationOpenDate" className="mt-3">
                <Form.Label>Application Open Date</Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="applicationOpenDate"
                  value={
                    selectedMatch.applicationOpenDate
                      ? new Date(selectedMatch.applicationOpenDate).toISOString().slice(0, 16)
                      : ''
                  }
                  onChange={handleMatchInputChange}
                />
              </Form.Group>
              <Form.Group controlId="applicationCloseDate" className="mt-3">
                <Form.Label>Application Close Date</Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="applicationCloseDate"
                  value={
                    selectedMatch.applicationCloseDate
                      ? new Date(selectedMatch.applicationCloseDate).toISOString().slice(0, 16)
                      : ''
                  }
                  onChange={handleMatchInputChange}
                />
              </Form.Group>
              <Form.Group controlId="location" className="mt-3">
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type="text"
                  name="location"
                  value={selectedMatch.location || ''}
                  onChange={handleMatchInputChange}
                />
              </Form.Group>
            </Form>
          ) : (
            <p>Loading match details...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseMatchModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveMatchDetails}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      
      {/* Applications Modal */}
      <Modal show={showApplicationsModal} onHide={handleCloseApplicationsModal}>
        <Modal.Header closeButton>
          <Modal.Title>Applications for Match</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {applicationsList.length > 0 ? (
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Member Number</th>
                  <th>Full Name</th>
                  <th>Age Group</th>
                  <th>Successful</th>
                  <th>Row No.</th>
                  <th>Seat Number</th>
                </tr>
              </thead>
              <tbody>
                {applicationsList.map((app, index) => (
                  <tr key={index}>
                    <td>{app.memberNumber}</td>
                    <td>{app.memberName}</td>
                    <td>{calculateTicketType(app.dob, app.memberName)}</td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        checked={app.successful ?? false}
                        onChange={async (e) => {
                          const updatedSuccessful = e.target.checked;
                          setApplicationsList((prevApplications) =>
                            prevApplications.map((application, idx) =>
                              idx === index
                                ? { ...application, successful: updatedSuccessful }
                                : application
                            )
                          );

                          // Save the change to the database
                          try {
                            await updateApplicationDetails(app.id, { successful: updatedSuccessful });
                          } catch (error) {
                            console.error('Error saving successful status:', error);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        value={app.rowNo || ''}
                        onChange={async (e) => {
                          const updatedRowNo = e.target.value;
                          setApplicationsList((prevApplications) =>
                            prevApplications.map((application, idx) =>
                              idx === index
                                ? { ...application, rowNo: updatedRowNo }
                                : application
                            )
                          );

                          // Save the change to the database
                          try {
                            await updateApplicationDetails(app.id, { rowNo: updatedRowNo });
                          } catch (error) {
                            console.error('Error saving row number:', error);
                          }
                        }}
                        size="sm"
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        value={app.seatNumber || ''}
                        onChange={async (e) => {
                          const updatedSeatNumber = e.target.value;
                          setApplicationsList((prevApplications) =>
                            prevApplications.map((application, idx) =>
                              idx === index
                                ? { ...application, seatNumber: updatedSeatNumber }
                                : application
                            )
                          );

                          // Save the change to the database
                          try {
                            await updateApplicationDetails(app.id, { seatNumber: updatedSeatNumber });
                          } catch (error) {
                            console.error('Error saving seat number:', error);
                          }
                        }}
                        size="sm"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No applications available for this match.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseApplicationsModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </Container>
  );
}

export default DashboardPage;

import React, { useState, useEffect } from 'react';

const Shop = () => {
  const [iframeSrc, setIframeSrc] = useState('https://www.teamwearworld.com/musc-cork'); // Default desktop URL

  useEffect(() => {
    const setIframeSource = () => {
      // Check if the device is mobile based on viewport width
      if (window.innerWidth <= 768) {
        setIframeSrc('https://www.teamwearworld.com/musc-cork?mobile=true'); // Mobile-specific URL
      } else {
        setIframeSrc('https://www.teamwearworld.com/musc-cork'); // Desktop URL
      }
    };

    // Set the initial iframe source
    setIframeSource();

    // Update the iframe source on window resize
    window.addEventListener('resize', setIframeSource);

    return () => {
      window.removeEventListener('resize', setIframeSource);
    };
  }, []);

  return (
    <div
      style={{
        position: 'relative',
        overflow: 'hidden',
        height: '100vh',
        margin: 0, // Remove any margins from the container
        padding: 0, // Remove any padding from the container
      }}
    >
      {/* Header Section */}
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '50px', // Fixed height for header
          backgroundColor: '#DA291C',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '20px',
          zIndex: 2, // Ensure header is above the iframe
        }}
      >
        <a
          href={iframeSrc} // Dynamic URL for mobile or desktop
          target="_blank"
          rel="noopener noreferrer"
          style={{
            fontSize: '18px',
            paddingTop: '120px',
          }}
        >
          Visit the MUSC Cork Shop
        </a>
      </div>

      {/* Fullscreen Iframe */}
      <div
        style={{
          position: 'fixed',
          top: '50px', // Offset by the header height
          left: 0,
          width: '100vw', // Ensure full width
          height: 'calc(100vh - 50px)', // Adjust height for header
          zIndex: 1,
          overflow: 'hidden', // Prevent scrolling behind the iframe
        }}
      >
        <iframe
          src={iframeSrc} // Dynamic source for iframe
          style={{
            width: '100vw', // Full width of the viewport
            height: '100%',
            border: 'none',
            margin: 0, // Remove margins
            padding: 0, // Remove padding
            paddingTop: '50px',
            display: 'block', // Prevent iframe inline spacing issues
          }}
          title="MUSC Cork Shop"
        ></iframe>
      </div>
    </div>
  );
};

export default Shop;
